import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import faqDoggie from "../images/faq-doggie.jpg"
import SEO from "../components/seo"

const FaqPage = ({ data }) => {
  const D = data
  const faqs = D.allContentfulFaqItem.edges
  const title = D.contentfulFaqPage.faqPageTitle
  const desc = D.contentfulFaqPage.seoDescription

  return (
    <Layout>
      <div className="px-6">
        <SEO title={title} description={desc} />
        <h1>{title}</h1>
      </div>
      {faqs.map(faq => (
        <div
          key={faq.node.id}
          className="md:max-w-4xl lg:mx-auto mx-6 my-6 p-6 bg-white md:flex rounded-lg shadow-light"
        >
          <img
            src={faqDoggie}
            alt={faq.node.name}
            className="md:h-24 md:w-24 md:min-h-24 md:min-w-24 h-16 w-16 min-h-16 min-w-16 rounded-full shadow-lg mx-auto md:self-end md:mr-6 mb-4"
          />

          <div className="text-center md:text-left faq">
            <cite className="text-black">{faq.node.name}</cite>
            <blockquote className="text-black text-sm">
              <span className="first">?</span> {faq.node.article.article}
              <span className="last">?</span>
            </blockquote>
          </div>
        </div>
      ))}
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allContentfulFaqItem(sort: { fields: updatedAt, order: ASC }) {
      edges {
        node {
          id
          name
          article {
            article
          }
          updatedAt
        }
      }
    }
    contentfulFaqPage {
      seoDescription
      faqPageTitle
    }
  }
`

export default FaqPage
